export interface IStock {
  id: number;
  ticker: string;
  exchangeCode: string;
  exchangeName: string;
  bbid: string;
  currency: string;
}

export interface IIdea {
  key: number;
  id: number;
  author: string;
  stockTicker: string;
  exchangeCode: string;
  exchangeName: string;
  stockPriceCurrency: string;
  ideaType: IdeaType;
  timeHorizon: TimeHorizon;
  conviction: Conviction;
  targetPrice: string;
  investmentAmount: string;
  investmentCurrency: string;
  style: Style;
  comment: string;
  isOpen: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IEdit {
  key: number;
  id: number;
  idea: number;
  action: string;
  changes: string;
  editedBySystem: boolean;
  createdAt: string;
}

export enum IdeaType {
  Long = 'Long',
  Short = 'Short',
}

export enum TimeHorizon {
  LessThan2Days = '< 2 days',
  LessThan1Week = '< 1 week',
  LessThan2Weeks = '< 2 weeks',
  LessThan1Month = '< 1 month',
  LessThan2Months = '< 2 months',
  LessThan3Months = '< 3 months',
  LessThan6Months = '< 6 months',
  MoreThan6Months = '> 6 months',
  MoreThan12Months = '> 12 months',
}

export enum Conviction {
  Medium = 'Medium',
  High = 'High',
  VeryHigh = 'Very high',
}

export enum Currency {
  Empty = '',
  USD = 'USD',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  CHF = 'CHF',
  AUD = 'AUD',
  CAD = 'CAD',
  AED = 'AED',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  ARS = 'ARS',
  ATS = 'ATS',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BEF = 'BEF',
  BGN = 'BGN',
  BHD = 'BHD',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BWP = 'BWP',
  BZD = 'BZD',
  CDF = 'CDF',
  CLP = 'CLP',
  CNH = 'CNH',
  COP = 'COP',
  CRC = 'CRC',
  CSD = 'CSD',
  CVE = 'CVE',
  CYP = 'CYP',
  CZK = 'CZK',
  DEM = 'DEM',
  DKK = 'DKK',
  DZD = 'DZD',
  ECS = 'ECS',
  EEK = 'EEK',
  EGP = 'EGP',
  ESP = 'ESP',
  FIM = 'FIM',
  FJD = 'FJD',
  FRF = 'FRF',
  GEL = 'GEL',
  GHC = 'GHC',
  GHS = 'GHS',
  GMD = 'GMD',
  GRD = 'GRD',
  GTQ = 'GTQ',
  HKD = 'HKD',
  HRK = 'HRK',
  HUF = 'HUF',
  IDR = 'IDR',
  IEP = 'IEP',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  ITL = 'ITL',
  JEP = 'JEP',
  JMD = 'JMD',
  JOD = 'JOD',
  KES = 'KES',
  KHR = 'KHR',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LTL = 'LTL',
  LUF = 'LUF',
  LVL = 'LVL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MKD = 'MKD',
  MNT = 'MNT',
  MOP = 'MOP',
  MTL = 'MTL',
  MUR = 'MUR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NLG = 'NLG',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PTE = 'PTE',
  PYG = 'PYG',
  QAR = 'QAR',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SCR = 'SCR',
  SEK = 'SEK',
  SGD = 'SGD',
  SIT = 'SIT',
  SKK = 'SKK',
  SLL = 'SLL',
  SZL = 'SZL',
  THB = 'THB',
  TND = 'TND',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  UYU = 'UYU',
  VES = 'VES',
  VND = 'VND',
  XAF = 'XAF',
  XCD = 'XCD',
  XDR = 'XDR',
  XEU = 'XEU',
  XOF = 'XOF',
  XPD = 'XPD',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum Style {
  Empty = '',
  EarningsBasedTrade = 'EARNINGS_BASED_TRADE',
  EconomicTrendTrade = 'ECONOMIC_TREND_TRADE',
  FundamentalAnalysis = 'FUNDAMENTAL_ANALYSIS',
  Growth = 'GROWTH',
  Momentum = 'MOMENTUM',
  NewsEvent = 'NEWS_EVENT',
  Opportunistic = 'OPPORTUNISTIC',
  SectorSpecificTrade = 'SECTOR_SPECIFIC_TRADE',
  TechnicalAnalysis = 'TECHNICAL_ANALYSIS',
  Value = 'VALUE',
  Others = 'OTHERS',
}
