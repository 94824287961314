import { useState, useEffect } from 'react';
import { Table, Space, Popover, Input, Button, Modal, Drawer } from 'antd';
import { EditOutlined, FileSearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import urlJoin from 'url-join';
import restful from '../../core/utilities/restful';
import { DrawerState, ResourceNames } from '../constants';

import axiosErrorHandler from '../../core/utilities/axiosErrorHandler';
import { IIdea } from '../types';
import { Routes } from '../../routes';
import { Roles } from '../../account/constants';
import AdminActivateView from './AdminActivateView';
import AdminIdeaDetailView from './AdminIdeaDetailView';

const { Search } = Input;

export default () => {
  const [ideas, setIdeas] = useState<Array<IIdea>>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalIdeas, setTotalIdeas] = useState(1);
  const [stockTickerFilter, setStockTickerFilter] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [title, setTitle] = useState<string>('');
  const [drawerState, setDrawerState] = useState<DrawerState>(
    DrawerState.closed
  );
  const [pk, setPk] = useState<number | null>(null);
  const navigate = useNavigate();

  const ideaAPI = restful(ResourceNames.ideas, Roles.admin);

  const columns = [
    {
      width: '7%',
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      render: (author: string) => author,
    },
    {
      width: '6%',
      title: 'Idea id',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => id,
    },
    {
      width: '6%',
      title: 'Stock ticker',
      dataIndex: 'stockTicker',
      key: 'stockTicker',
      render: (stockTicker: string) => stockTicker,
    },
    {
      width: '10%',
      title: 'Exchange',
      dataIndex: 'exchangeName',
      key: 'exchangeName',
      render: (exchangeName: string) => exchangeName,
    },
    {
      width: '9%',
      title: 'Bbid',
      dataIndex: 'bbid',
      key: 'bbid',
      render: (bbid: string) => bbid,
    },
    {
      width: '7%',
      title: 'Stock price currency',
      dataIndex: 'stockPriceCurrency',
      key: 'stockPriceCurrency',
      render: (stockPriceCurrency: string) => stockPriceCurrency,
    },
    {
      width: '5%',
      title: 'Type',
      dataIndex: 'ideaType',
      key: 'ideaType',
      render: (ideaType: string) => ideaType,
    },
    {
      width: '7%',
      title: 'Time horizon',
      dataIndex: 'timeHorizon',
      key: 'timeHorizon',
      render: (timeHorizon: string) => timeHorizon,
    },
    {
      width: '7%',
      title: 'Conviction',
      dataIndex: 'conviction',
      key: 'conviction',
      render: (conviction: string) => conviction,
    },
    {
      width: '7%',
      title: 'Target price',
      dataIndex: 'targetPrice',
      key: 'targetPrice',
      render: (targetPrice: string) => targetPrice,
    },
    {
      width: '7%',
      title: 'Investment amount',
      dataIndex: 'investmentAmount',
      key: 'investmentAmount',
      render: (investmentAmount: string) => investmentAmount,
    },
    {
      width: '7%',
      title: 'Investment currency',
      dataIndex: 'investmentCurrency',
      key: 'investmentCurrency',
      render: (investmentCurrency: string) => investmentCurrency,
    },
    {
      width: '14%',
      title: 'Style',
      dataIndex: 'style',
      key: 'style',
      render: (style: string) => style,
    },
    {
      width: '5%',
      title: 'Is open?',
      dataIndex: 'isOpen',
      key: 'isOpen',
      render: (isOpen: string) => (isOpen ? 'Yes' : 'No'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: { id: number; isOpen: boolean }) => (
        <Space size='middle'>
          <Popover content='view' trigger='hover'>
            <a>
              <EditOutlined onClick={onViewIdea(record)} />
            </a>
          </Popover>
          <Popover content='view history' trigger='hover'>
            <a onClick={onViewIdeaHistory(record)}>
              <FileSearchOutlined />
            </a>
          </Popover>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await updateIdeas(1);
      } catch (err: any) {
        axiosErrorHandler(err, true);
      } finally {
        setLoading(false);
      }
    };
    load();
  }, []);

  const onViewIdea =
    ({ id }: { id: number }) =>
    () => {
      setLoading(true);
      setPk(id);
      setTitle('View idea');
      setDrawerState(DrawerState.ideaEdit);
      setLoading(false);
    };

  const updateIdeas = async (page: number, stockTicker?: string) => {
    const ideasResponse = await getIdeasResponse(page, stockTicker);
    setIdeas(
      ideasResponse.data.results.map((idea: IIdea) => ({
        ...idea,
        key: idea.id,
      }))
    );
    setTotalIdeas(ideasResponse.data.count);
  };

  const getIdeasResponse = async (page: number, stockTicker?: string) =>
    await ideaAPI.list({ params: { page, stockTicker: stockTicker || '' } });

  const onViewIdeaHistory =
    ({ id }: { id: number }) =>
    () => {
      const url = urlJoin(Routes.ideas, `${id}`, 'history');
      navigate(url);
    };

  const onChangePage = async (page: number, pageSize: number) => {
    try {
      setLoading(true);
      await updateIdeas(page, stockTickerFilter);
      setCurrentPage(page);
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
    }
  };

  const onSearchStockTicker = async (value: string) => {
    try {
      setStockTickerFilter(value);
      setLoading(true);
      await updateIdeas(1, value);
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
    }
  };

  const getIdea = (pk: number) =>
    ideas.find(idea => idea.id === pk) || ideas[0];

  const onClose = () => {
    setDrawerState(DrawerState.closed);
  };

  return (
    <div>
      <Space direction='horizontal'>
        <Button onClick={() => setIsModalVisible(true)}>Activate emails</Button>
        <Modal
          visible={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
        >
          <AdminActivateView />
        </Modal>
        <Search
          placeholder='Search stock ticker'
          onSearch={onSearchStockTicker}
          enterButton
        />
      </Space>
      <Table
        loading={loading}
        columns={columns}
        dataSource={ideas}
        pagination={{
          showQuickJumper: true,
          onChange: onChangePage,
          total: totalIdeas,
          current: currentPage,
          pageSize: 10,
        }}
      />
      <Drawer
        title={title}
        width={window.innerWidth * 0.5}
        onClose={onClose}
        visible={drawerState !== DrawerState.closed}
      >
        {pk !== null && drawerState === DrawerState.ideaEdit && (
          <AdminIdeaDetailView ideaData={getIdea(pk)} />
        )}
      </Drawer>
    </div>
  );
};
