import { Button, Form, Input, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosErrorHandler, restful } from '../../core';
import { Routes } from '../../routes';
import { ResourceNames } from '../constants';
import { useAuth } from '../hook';

export default () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const userAPI = restful(ResourceNames.users);

  const onSubmitEmail = async () => {
    try {
      setLoading(true);
      await userAPI.postListAction({
        action: 'find_username',
        data: form.getFieldsValue(),
      });
      message.success('You will receive an email shortly with your username.');
      await auth.logout();
      navigate(Routes.home);
    } catch (err: any) {
      const errs = err.response.data;
      if (errs.email) {
        message.error(errs.email);
      } else {
        axiosErrorHandler(err, true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name='ideaForm'
      onFinish={onSubmitEmail}
      labelCol={{
        span: 9,
      }}
      wrapperCol={{
        span: 6,
      }}
      validateTrigger=''
    >
      <Form.Item
        label='Email'
        name='email'
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 9,
        }}
      >
        <Button loading={loading} type='primary' htmlType='submit'>
          Send username
        </Button>
      </Form.Item>
    </Form>
  );
};
