import { AxiosError } from 'axios';
import { message } from 'antd';

export default (error: AxiosError, autoMessage: boolean) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const result = error.response.data as any;
    // console.log(error.response.status);
    if (autoMessage) message.error(`${error.message}: ${result.detail}`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    if (autoMessage)
      message.error(`No response was received: ${error.request}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log('Error', error.message);
    message.error(
      `Something happened in setting up the request that triggered an Error: ${error.message}`
    );
  }
  // console.log(error.config);
};
