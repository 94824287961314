import urlJoin from 'url-join';

import config from '../config';

const getURL = (action: string) => {
  const { baseURL } = config;
  const fullURL = urlJoin(baseURL, action);
  return fullURL;
};

export default {
  api: {
    obtainPairURL: getURL('/api/token-obtain-pair/'),
    refreshURL: getURL('/api/token-refresh/'),
    verifyURL: getURL('/api/token-verify/'),
    profileURL: getURL('/api/profile/'),
    registerURL: getURL('/api/auth/register/'),
  },
};
