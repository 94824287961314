import { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import {
  Conviction,
  Currency,
  IdeaType,
  IIdea,
  Style,
  TimeHorizon,
} from '../types';

const { Option } = Select;

const ItemSelect = ({
  placeholder,
  value,
  onSelect,
  options,
  doFilterSort,
}: {
  placeholder?: string;
  value: null | string;
  onSelect: (value: string) => void;
  options: Array<string>;
  doFilterSort?: boolean;
}) => (
  <Select
    showSearch
    optionFilterProp='children'
    placeholder={placeholder}
    onSelect={onSelect}
    value={value}
    filterOption={(input, option) =>
      (option!.children as unknown as string)
        .toLowerCase()
        .includes(input.toLowerCase())
    }
    filterSort={
      doFilterSort
        ? (optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
        : undefined
    }
  >
    {options.map((option: string) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    ))}
  </Select>
);

// https://ant.design/components/input/#API
const NumericInput = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { value, onChange } = props;

  const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '') {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    if (value) {
      let valueTemp = value;
      if (value.charAt(value.length - 1) === '.') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    }
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder='Input a number or leave empty'
      maxLength={30}
    />
  );
};

export default ({ ideaData }: { ideaData: IIdea }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(ideaData);
  }, []);

  // logic when select for most fields
  const onChangeField = (field: string, value: string) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  return (
    <Form form={form} layout='vertical' disabled>
      <Form.Item
        label='Stock ticker'
        name='stockTicker'
        rules={[{ required: true }]}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Exchange'
        name='exchangeName'
        rules={[{ required: true }]}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Stock price currency'
        name='stockPriceCurrency'
        rules={[{ required: true }]}
      >
        <Input
          readOnly
          disabled
          value={form.getFieldValue('stockPriceCurrency')}
        />
      </Form.Item>
      <Form.Item label='Idea type' name='ideaType' rules={[{ required: true }]}>
        <ItemSelect
          value={form.getFieldValue('ideaType')}
          onSelect={value => onChangeField('ideaType', value)}
          options={Object.values(IdeaType)}
          doFilterSort
        />
      </Form.Item>
      <Form.Item
        label='Time horizon'
        name='timeHorizon'
        rules={[{ required: true }]}
      >
        <ItemSelect
          value={form.getFieldValue('timeHorizon')}
          onSelect={value => onChangeField('timeHorizon', value)}
          options={Object.values(TimeHorizon)}
        />
      </Form.Item>
      <Form.Item
        label='Conviction'
        name='conviction'
        rules={[{ required: true }]}
      >
        <ItemSelect
          value={form.getFieldValue('conviction')}
          onSelect={value => onChangeField('conviction', value)}
          options={Object.values(Conviction)}
        />
      </Form.Item>
      <Form.Item label='Target price' name='targetPrice'>
        <NumericInput
          value={form.getFieldValue('targetPrice')}
          onChange={value => onChangeField('targetPrice', value)}
        />
      </Form.Item>
      <Form.Item label='Investment amount' name='investmentAmount'>
        <NumericInput
          value={form.getFieldValue('investmentAmount')}
          onChange={value => onChangeField('investmentAmount', value)}
        />
      </Form.Item>
      <Form.Item label='Investment currency' name='investmentCurrency'>
        <ItemSelect
          value={form.getFieldValue('investmentCurrency')}
          onSelect={value => onChangeField('investmentCurrency', value)}
          options={Object.values(Currency)}
        />
      </Form.Item>
      <Form.Item label='Style' name='style'>
        <ItemSelect
          value={form.getFieldValue('style')}
          onSelect={value => onChangeField('style', value)}
          options={Object.values(Style)}
        />
      </Form.Item>
      <Form.Item label='Comment' name='comment'>
        <Input
          value={form.getFieldValue('comment')}
          onChange={e => onChangeField('comment', e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};
