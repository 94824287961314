import React, { useState, useEffect, useMemo } from 'react';
import { authenticate, logout, verifyToken } from './index';
import { ProfileType } from './types';
import restful from '../core/utilities/restful';
import { ResourceNames } from './constants';
import axiosErrorHandler from '../core/utilities/axiosErrorHandler';

interface AuthContextType {
  loading: boolean;
  isAuthenticated: boolean;
  authenticate: (
    username: string,
    password: string,
    onSuccess: Function
  ) => void;
  logout: Function;
}

const AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    loadSessionForFirstTime();
  }, []);

  const authenticateWrapper = async (
    username: string,
    password: string,
    onSuccess: Function
  ): Promise<void> => {
    try {
      setLoading(true);
      const result = await authenticate(username, password);
      if (result === true) {
        setIsAuthenticated(true);
        onSuccess();
      } else {
        setIsAuthenticated(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const logoutWrapper = async (): Promise<void> => {
    setLoading(true);
    await logout();
    setIsAuthenticated(false);
    setLoading(false);
  };

  const loadSessionForFirstTime = async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await verifyToken(false);
      if (result === true) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const getValue = () => ({
    loading,
    isAuthenticated,
    authenticate: authenticateWrapper,
    logout: logoutWrapper,
  });
  const value = useMemo(() => getValue(), [loading, isAuthenticated]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

interface IProfileContext {
  profile: ProfileType;
  loading: boolean;
  getProfile: () => Promise<void>;
}

const ProfileContext = React.createContext<IProfileContext>(null!);

function ProfileProvider({ children }: { children: React.ReactNode }) {
  const profileAPI = restful(ResourceNames.users);
  const [profile, setProfile] = useState<ProfileType>(null!);
  const [loading, setLoading] = useState(true);

  const getProfile = async (): Promise<void> => {
    try {
      setLoading(true);
      const profile = (await profileAPI.list({})).data[0];
      setProfile({
        username: profile.username,
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        role: profile.role,
        isSuperuser: profile.isSuperuser,
      });
    } catch (err: any) {
      axiosErrorHandler(err, false);
    } finally {
      setLoading(false);
    }
  };

  const getValue = () => ({
    profile,
    loading,
    getProfile,
  });
  const value = useMemo(() => getValue(), [profile, loading]);

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
}

export { AuthContext, AuthProvider, ProfileContext, ProfileProvider };
