import { Descriptions } from 'antd';
import { useProfile } from '../hook';

export default () => {
  const profile = useProfile();

  return (
    <Descriptions title='User profile' column={1}>
      <Descriptions.Item label='User name'>
        {profile.profile.username}
      </Descriptions.Item>
      <Descriptions.Item label='Email'>
        {profile.profile.email}
      </Descriptions.Item>
      <Descriptions.Item label='First name'>
        {profile.profile.firstName}
      </Descriptions.Item>
      <Descriptions.Item label='Last name'>
        {profile.profile.lastName}
      </Descriptions.Item>
      <Descriptions.Item label='Role'>{profile.profile.role}</Descriptions.Item>
    </Descriptions>
  );
};
