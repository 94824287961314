import { useState } from 'react';
import { Input, Button, Form, message } from 'antd';
import restful from '../../core/utilities/restful';
import { ResourceNames } from '../constants';

import { Roles } from '../../account/constants';
import { axiosErrorHandler } from '../../core';

export default () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const userAPI = restful(ResourceNames.users, Roles.admin);

  const onFinish = async () => {
    try {
      setLoading(true);
      const emails = form.getFieldValue('emails');
      const response = await userAPI.postListAction({
        action: 'add_author_group',
        data: { emails: emails.split('\n') },
      });
      const nonExistedEmails = response.data.nonExisted;
      if (response.data.nonExisted.length > 0) {
        message.warn(
          `Successfully activated emails, except the following non-existed emails: ${nonExistedEmails}`
        );
      } else {
        message.success('Successfully activated emails!');
      }
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Form form={form} layout='vertical' name='ideaForm' onFinish={onFinish}>
        <Form.Item label='E-mails' name='emails'>
          <Input.TextArea
            placeholder='Input E-mails, one per line'
            autoSize={{ minRows: 3, maxRows: 20 }}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading} type='primary' htmlType='submit'>
            Activate
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
