import { Spin, Button, Result, message } from 'antd';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useEffect } from 'react';
import DefaultLayout from './layout';

import { useAuth, useProfile } from './account/hook';

import { AuthProvider, ProfileProvider } from './account/context';
import LoginPage from './account/components/LoginPage';

import { ProfilePage } from './account';
import {
  AdminIdeaListView,
  AuthorIdeaListView,
  IdeaHistoryListView,
} from './idea';
import ForgotPasswordView from './account/components/ForgotPasswordView';
import ResetPasswordView from './account/components/ResetPasswordView';
import RegisterView from './account/components/RegisterView';
import ForgotUsernameView from './account/components/ForgotUsernameView';

const CheckAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const profile = useProfile();

  useEffect(() => {
    const getUserInfo = async () => {
      if (auth.isAuthenticated === true) {
        await profile.getProfile();
      }
    };
    getUserInfo();
  }, [auth.isAuthenticated]);
  if (auth.loading || (auth.isAuthenticated && profile.loading)) {
    return <Spin />;
  }
  return children;
};

const CheckLogin = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  if (auth.isAuthenticated) {
    message.warn('Please logout first');
    return <Navigate to='/ideas' />;
  }
  return children;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const location = useLocation();
  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} />;
  }
  return children;
};

const Redirect = () => {
  const auth = useAuth();
  if (auth.isAuthenticated) {
    return <Navigate to='/ideas' />;
  }
  return <Navigate to='/login' />;
};

const IdeasPage = () => {
  const profile = useProfile();
  if (profile.profile.isSuperuser) {
    return <AdminIdeaListView />;
  }
  return <AuthorIdeaListView />;
};

const NonExistPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      status='404'
      title='404'
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Button type='primary' onClick={() => navigate('/ideas')}>
          Back Home
        </Button>
      }
    />
  );
};

type IdeaParams = {
  ideaId: string;
};

const HistoryPage = () => {
  const { ideaId } = useParams<IdeaParams>();
  const profile = useProfile();
  if (!ideaId) {
    return <NonExistPage />;
  }
  if (profile.profile.isSuperuser) {
    return <IdeaHistoryListView ideaId={parseInt(ideaId)} isAdmin />;
  }
  return <IdeaHistoryListView ideaId={parseInt(ideaId)} />;
};

const RegisterPage = () => <RegisterView />;

const ForgotPasswordPage = () => <ForgotPasswordView />;

type ResetPasswordParams = {
  token: string;
};

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (!token) {
    message.error('No reset token');
    return <Navigate to='/login' />;
  }
  return <ResetPasswordView token={token} />;
};

const ForgotUsernamePage = () => <ForgotUsernameView />;

const App = () => (
  <AuthProvider>
    <ProfileProvider>
      <Router>
        <CheckAuth>
          <DefaultLayout>
            <Routes>
              <Route path='*' element={<NonExistPage />} />
              <Route path='/' element={<Redirect />} />
              <Route
                path='/forgotusername'
                element={
                  <CheckLogin>
                    <ForgotUsernamePage />
                  </CheckLogin>
                }
              />
              <Route
                path='/forgotpassword'
                element={
                  <CheckLogin>
                    <ForgotPasswordPage />
                  </CheckLogin>
                }
              />
              <Route
                path='/resetpassword'
                element={
                  <CheckLogin>
                    <ResetPasswordPage />
                  </CheckLogin>
                }
              />
              <Route
                path='/register'
                element={
                  <CheckLogin>
                    <RegisterPage />
                  </CheckLogin>
                }
              />
              <Route
                path='/login'
                element={
                  <CheckLogin>
                    <LoginPage />
                  </CheckLogin>
                }
              />
              <Route
                path='/profile'
                element={
                  <RequireAuth>
                    <ProfilePage />
                  </RequireAuth>
                }
              />
              <Route
                path='/ideas'
                element={
                  <RequireAuth>
                    <IdeasPage />
                  </RequireAuth>
                }
              />
              <Route
                path='/ideas/:ideaId/history'
                element={
                  <RequireAuth>
                    <HistoryPage />
                  </RequireAuth>
                }
              />
            </Routes>
          </DefaultLayout>
        </CheckAuth>
      </Router>
    </ProfileProvider>
  </AuthProvider>
);

export default App;
