import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { Roles } from '../../account/constants';
import { useProfile } from '../../account/hook';
import { axiosErrorHandler, restful } from '../../core';
import { ResourceNames } from '../constants';
import { IEdit } from '../types';

const IdeaChanges = ({ changes }: { changes: string }) => {
  const _changes = changes.trim();
  if (_changes === '') return <></>;
  const json = JSON.parse(_changes);
  return (
    <ul>
      {Object.entries(json).map(([key, value]: any) => (
        <li key={key}>{`${key}: ${value.old} -> ${value.new}`}</li>
      ))}
    </ul>
  );
};

export default ({ ideaId, isAdmin }: { ideaId: number; isAdmin?: boolean }) => {
  const [edits, setEdits] = useState<Array<IEdit>>([]);
  const [loading, setLoading] = useState(false);
  const [totalEdits, setTotalEdits] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const profile = useProfile();

  const historyAPI = isAdmin
    ? restful(ResourceNames.history, Roles.admin)
    : restful(ResourceNames.history);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await updateHistory(1);
        setLoading(false);
      } catch (err: any) {
        axiosErrorHandler(err, true);
      }
    };
    load();
  }, []);

  const updateHistory = async (page: number) => {
    const response = await getHistoryResponse(page);
    if (response) {
      setEdits(
        response.data.results.map((edit: IEdit) => ({ ...edit, key: edit.id }))
      );
      setTotalEdits(response.data.count);
    }
  };

  const getHistoryResponse = async (page: number) => {
    try {
      setLoading(true);
      return await historyAPI.list({ params: { page, ideaId } });
    } catch (err: any) {
      axiosErrorHandler(err, true);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      width: '6%',
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (action: string) => action,
    },
    {
      width: '12%',
      title: 'Edit time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) =>
        `${createdAt.substring(0, 10)} ${createdAt.substring(11, 19)}`,
    },
    {
      width: '7%',
      title: 'Edited by',
      dataIndex: 'editedBySystem',
      key: 'editedBySystem',
      render: (editedBySystem: boolean) =>
        editedBySystem ? 'System' : 'Author',
    },
    {
      width: '75%',
      title: 'Changes',
      dataIndex: 'changes',
      key: 'changes',
      render: (changes: string) => <IdeaChanges changes={changes} />,
    },
  ];

  const onChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    await updateHistory(page);
    setCurrentPage(page);
    setLoading(false);
  };

  return (
    <>
      <h1>Idea history</h1>
      <Table
        loading={loading}
        columns={columns}
        dataSource={edits}
        pagination={{
          showQuickJumper: true,
          onChange: onChangePage,
          total: totalEdits,
          current: currentPage,
          pageSize: 10,
        }}
      />
    </>
  );
};
