import { UserTokens } from './types';

const getLocalRefreshToken = () => {
  const user = localStorage.getItem('user');
  return user === null ? null : JSON.parse(user).refresh;
};

const getLocalAccessToken = () => {
  const user = localStorage.getItem('user');
  return user === null ? null : JSON.parse(user).access;
};

const updateLocalAccessToken = (token: string) => {
  const user = localStorage.getItem('user');
  if (user) {
    const parsedUser = JSON.parse(user) as unknown as UserTokens;
    parsedUser.access = token;
    setUser(parsedUser);
  }
};

const getUser = () => {
  const user = localStorage.getItem('user');
  return user === null ? null : JSON.parse(user);
};

const setUser = (user: UserTokens) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem('user');
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
