import React from 'react';
import ReactDOM from 'react-dom';
import axios, { AxiosDefaults, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.min.css';
import './index.css';
import TokenService from './account/token';
import { logout, memoizedRefreshAccessToken } from './account';

const instance = axios.create({
  baseURL: 'http://kien-pc.dtl:3000/',
});

axios.interceptors.request.use(
  (config: any): AxiosDefaults<any> => {
    const newConfig = { ...config };
    // if (localStorage.getItem('token')) {
    //  newConfig.headers.common.Authorization = `JWT ${localStorage.getItem('token')}`;
    // }
    // if (newConfig.headers['Content-Type'] === 'multipart/form-data')
    //  return newConfig;
    const token = TokenService.getLocalAccessToken();
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
      // newConfig.headers.common.Authorization = `Bearer ${token}`;
    }
    if (newConfig.params) {
      newConfig.params = decamelizeKeys(newConfig.params);
    }
    if (newConfig.data) {
      newConfig.data = decamelizeKeys(newConfig.data);
    }
    return newConfig;
  }
  // (error: any) => {
  //  return Promise.reject(error);
  // }
);

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      response.data &&
      response.headers['content-type'] === 'application/json'
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  async err => {
    if (err.response) {
      // Access Token was expired
      const originalRequest = err.config;
      if (err.response.status === 401 && !originalRequest._retry) {
        if (err.config.url.endsWith('token-refresh/')) {
          // Refresh fail, force login again
          return Promise.reject(err);
        }
        originalRequest._retry = true;
        if (TokenService.getLocalRefreshToken() !== null) {
          const res = await memoizedRefreshAccessToken();
          if (res === true) {
            const token = TokenService.getLocalAccessToken();
            originalRequest.headers.Authorization = `Bearer ${token}`;
            if (err.config.url.endsWith('token-verify/')) {
              // Retry new access token
              return Promise.reject(err);
            }
            return axios(originalRequest);
          }
        }
      }
    }
    if (err.response?.status === 401) {
      logout();
    }
    return Promise.reject(err);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
