import { useEffect, useState } from 'react';
import { Button, Form, Input, message, Popconfirm, Select, Space } from 'antd';
import { ResourceNames } from '../constants';
import restful from '../../core/utilities/restful';
import {
  Conviction,
  Currency,
  IdeaType,
  IIdea,
  Style,
  TimeHorizon,
} from '../types';
import { axiosErrorHandler } from '../../core';

const { Option } = Select;

const ItemSelect = ({
  placeholder,
  value,
  onSelect,
  options,
  doFilterSort,
}: {
  placeholder?: string;
  value: null | string;
  onSelect: (value: string) => void;
  options: Array<string>;
  doFilterSort?: boolean;
}) => (
  <Select
    showSearch
    optionFilterProp='children'
    placeholder={placeholder}
    onSelect={onSelect}
    value={value}
    filterOption={(input, option) =>
      (option!.children as unknown as string)
        .toLowerCase()
        .includes(input.toLowerCase())
    }
    filterSort={
      doFilterSort
        ? (optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
        : undefined
    }
  >
    {options.map((option: string) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    ))}
  </Select>
);

// https://ant.design/components/input/#API
const NumericInput = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { value, onChange } = props;

  const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '') {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    if (value) {
      let valueTemp = value;
      if (value.charAt(value.length - 1) === '.') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    }
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder='Input a number or leave empty'
      maxLength={30}
    />
  );
};

export default ({
  ideaData,
  onSubmitPostProcess,
  closeDrawer,
}: {
  ideaData: IIdea;
  onSubmitPostProcess: () => Promise<void>;
  closeDrawer: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(ideaData);
  }, []);

  const ideaAPI = restful(ResourceNames.ideas);

  // logic when select for most fields
  const onChangeField = (field: string, value: string) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const onSubmitIdea = async () => {
    try {
      setLoading(true);
      const idea = ideaData as any;
      const changes: any = {};
      for (const key of ['targetPrice', 'investmentAmount']) {
        if (form.getFieldValue(key) === '') {
          form.setFieldValue(key, null);
        }
      }
      const fieldsValue: IIdea = form.getFieldsValue();
      for (const [key, value] of Object.entries(fieldsValue)) {
        if (idea[key] !== value) {
          changes[key] = value;
        }
      }
      if (Object.keys(changes).length === 0) {
        message.info('Idea not changed');
      } else {
        await ideaAPI.update({ data: changes, pk: form.getFieldValue('id') });
        await onSubmitPostProcess();
        message.success('Idea edited');
      }
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
      closeDrawer();
    }
  };

  const getRequireMessage = (s: string) => `Please select ${s}`;

  const closeIdea = async () => {
    try {
      setLoading(true);
      await ideaAPI.update({ pk: ideaData.id, data: { isOpen: false } });
      await onSubmitPostProcess();
      closeDrawer();
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onSubmitIdea}
      disabled={!ideaData.isOpen}
    >
      <Form.Item
        label='Stock ticker'
        name='stockTicker'
        rules={[{ required: true }]}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Exchange'
        name='exchangeName'
        rules={[{ required: true }]}
      >
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label='Stock price currency'
        name='stockPriceCurrency'
        rules={[{ required: true }]}
      >
        <Input
          readOnly
          disabled
          value={form.getFieldValue('stockPriceCurrency')}
        />
      </Form.Item>
      <Form.Item label='Idea type' name='ideaType' rules={[{ required: true }]}>
        <ItemSelect
          value={form.getFieldValue('ideaType')}
          onSelect={value => onChangeField('ideaType', value)}
          options={Object.values(IdeaType)}
          doFilterSort
        />
      </Form.Item>
      <Form.Item
        label='Time horizon'
        name='timeHorizon'
        rules={[{ required: true }]}
      >
        <ItemSelect
          value={form.getFieldValue('timeHorizon')}
          onSelect={value => onChangeField('timeHorizon', value)}
          options={Object.values(TimeHorizon)}
        />
      </Form.Item>
      <Form.Item
        label='Conviction'
        name='conviction'
        rules={[{ required: true }]}
      >
        <ItemSelect
          value={form.getFieldValue('conviction')}
          onSelect={value => onChangeField('conviction', value)}
          options={Object.values(Conviction)}
        />
      </Form.Item>
      <Form.Item label='Target price' name='targetPrice'>
        <NumericInput
          value={form.getFieldValue('targetPrice')}
          onChange={value => onChangeField('targetPrice', value)}
        />
      </Form.Item>
      <Form.Item label='Investment amount' name='investmentAmount'>
        <NumericInput
          value={form.getFieldValue('investmentAmount')}
          onChange={value => onChangeField('investmentAmount', value)}
        />
      </Form.Item>
      <Form.Item label='Investment currency' name='investmentCurrency'>
        <ItemSelect
          value={form.getFieldValue('investmentCurrency')}
          onSelect={value => onChangeField('investmentCurrency', value)}
          options={Object.values(Currency)}
        />
      </Form.Item>
      <Form.Item label='Style' name='style'>
        <ItemSelect
          value={form.getFieldValue('style')}
          onSelect={value => onChangeField('style', value)}
          options={Object.values(Style)}
        />
      </Form.Item>
      <Form.Item label='Comment' name='comment'>
        <Input
          value={form.getFieldValue('comment')}
          onChange={e => onChangeField('comment', e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        {ideaData.isOpen && (
          <Space>
            <Popconfirm title='Confirm edit?' onConfirm={onSubmitIdea}>
              <Button loading={loading} type='primary' htmlType='submit'>
                Submit
              </Button>
            </Popconfirm>
            <Popconfirm
              title='Confirm close? You will not be able to edit this idea after closing.'
              onConfirm={closeIdea}
            >
              <Button loading={loading} htmlType='button'>
                Close idea
              </Button>
            </Popconfirm>
          </Space>
        )}
      </Form.Item>
    </Form>
  );
};
