export const Routes = {
  home: '/',
  login: '/login',
  forgotPassword: '/forgotpassword',
  forgotUsername: '/forgotusername',
  resetPassword: '/resetpassword',
  register: '/register',
  profile: '/profile',
  ideas: '/ideas',
};
