export const ResourceNames = {
  passwordReset: 'password_reset',
  groups: 'groups',
  users: 'users',
};

export const Roles = {
  admin: 'admin',
};

export const AccountRoles = ['Sales', 'Research analyst', 'Investment advisor'];
