import axios from 'axios';
import qs from 'qs';
import config from '../../config';

interface IURL {
  pk?: string | number;
  action?: string;
}

interface IRequest<T> extends IURL {
  data?: Partial<T>;
  params?: object;
}

const paramsSerializer = (params: object) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

export default <T>(resource: string, role?: string) => {
  /**
   *
   * @param role: could be `version` or role + version, e.g: v1, adminv1.
   * @param resources: the resources
   * @param pk: primary key of the inner most resource, if the param is not defined that would be consider like an action on list.
   * @param action: extra action beyond default actions
   */

  let url;
  let { baseURL } = config;
  while (baseURL.endsWith('/')) baseURL = baseURL.slice(0, -1);
  if (role !== undefined) {
    url = `${baseURL}/${role}/api/${resource}`;
  } else {
    url = `${baseURL}/api/${resource}`;
  }
  const prefix = url;

  const getURL = ({ pk, action }: IURL) => {
    let url = prefix;
    if (pk !== undefined) {
      url = `${url}/${pk}`;
    }
    if (action !== undefined) {
      url = `${url}/${action}`;
    }
    return `${url}/`;
  };

  const retrieve = ({ pk, params }: IRequest<T>) => {
    const url = getURL({ pk });
    return axios.get(url, { params, paramsSerializer });
  };

  const create = ({ data, params }: IRequest<T>) => {
    const url = getURL({});
    return axios.post(url, { ...data }, { params, paramsSerializer });
  };

  const list = ({ params }: IRequest<T>) => {
    const url = getURL({});
    return axios.get(url, { params, paramsSerializer });
  };

  const update = ({ pk, data, params }: IRequest<T>) => {
    const url = getURL({ pk });
    return axios.patch(url, { ...data }, { params, paramsSerializer });
  };

  const destroy = ({ pk, params }: IRequest<T>) => {
    const url = getURL({ pk });
    return axios.delete(url, { params, paramsSerializer });
  };

  const getDetailAction = ({ pk, action, params }: IRequest<T>) => {
    const url = getURL({ pk, action });
    return axios.get(url, { params, paramsSerializer });
  };

  const postDetailAction = ({ pk, action, data, params }: IRequest<T>) => {
    const url = getURL({ pk, action });
    return axios.post(url, { ...data }, { params, paramsSerializer });
  };

  const getListAction = ({ pk, action, params }: IRequest<T>) => {
    const url = getURL({ pk, action });
    return axios.get(url, { params, paramsSerializer });
  };

  const postListAction = ({ action, data, params }: IRequest<T>) => {
    const url = getURL({ action });
    return axios.post(url, { ...data }, { params, paramsSerializer });
  };

  return {
    retrieve,
    list,
    update,
    destroy,
    create,
    getDetailAction,
    postDetailAction,
    getListAction,
    postListAction,
  };
};
