import { Button, Form, Input, message, Select } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosErrorHandler, restful } from '../../core';
import { Routes } from '../../routes';
import { AccountRoles, ResourceNames } from '../constants';
import { useAuth } from '../hook';

export default () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const userAPI = restful(ResourceNames.users);

  const onFinish = async () => {
    try {
      setLoading(true);
      await userAPI.postListAction({
        action: 'register',
        data: {
          firstName: form.getFieldValue('firstname'),
          lastName: form.getFieldValue('lastname'),
          username: form.getFieldValue('username'),
          email: form.getFieldValue('email'),
          password: form.getFieldValue('password'),
          role: form.getFieldValue('role'),
        },
      });
      navigate(Routes.home);
      message.success('Register successfully!');
      await auth.logout();
    } catch (err: any) {
      if (err.response.status === 400) {
        const errs = err.response.data;
        if (errs.firstname) {
          message.error('Invalid first name!');
        } else if (errs.lastname) {
          message.error('Invalid last name!');
        } else if (errs.username) {
          const msg = errs.username.join('\n');
          message.error(`Username error: ${msg}`, 10);
        } else if (errs.email) {
          message.error('Please change to another email');
        } else if (errs.password) {
          const msg = errs.password.join('\n');
          message.error(`Password error: ${msg}`, 10);
        }
      } else {
        axiosErrorHandler(err, true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      labelCol={{
        span: 9,
      }}
      wrapperCol={{
        span: 6,
      }}
      name='register'
      initialValues={{ role: 'Sales' }}
      onFinish={onFinish}
      scrollToFirstError
      validateTrigger=''
    >
      <Form.Item
        label='First name'
        name='firstname'
        rules={[{ required: true, message: 'Please input your first name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Last name'
        name='lastname'
        rules={[
          {
            required: true,
            message:
              "Please input your last name. If you don't have one, please input N.A",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label='Username'
        name='username'
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name='email'
        label='E-mail'
        rules={[
          {
            type: 'email',
            message: 'The input is not a valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input placeholder='Please use your company email to register' />
      </Form.Item>
      <Form.Item
        name='password'
        label='Password'
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name='rePassword'
        label='Confirm Password'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label='Password requirements'>
        <span className='ant-form-text'>
          <li>Not a common password or too similar to your username</li>
          <li>Have at least 8 characters</li>
          <li>Have at least 1 lowercase character</li>
          <li>Have at least 1 uppercase character</li>
          <li>Have at least 1 digit (0 to 9)</li>
        </span>
      </Form.Item>
      <Form.Item label='Role' name='role' rules={[{ required: true }]}>
        <Select
          options={AccountRoles.map(role => ({ value: role, label: role }))}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 9,
        }}
      >
        <Button loading={loading} type='primary' htmlType='submit'>
          Register
        </Button>
      </Form.Item>
    </Form>
  );
};
