import { Form, Input, Button, message, Checkbox, Row, Col } from 'antd';

import { useNavigate, useLocation, Navigate, Link } from 'react-router-dom';
import { Routes } from '../../routes';

import { useAuth } from '../hook';

interface BasicCredentials {
  username: string;
  password: string;
  remember: boolean;
}

type LocationProps = {
  state: {
    from: Location;
  };
};

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation() as LocationProps;
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  const onFinish = (values: BasicCredentials) => {
    auth.authenticate(values.username, values.password, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, { replace: true });
    });
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Form
      name='basic'
      labelCol={{
        span: 9,
      }}
      wrapperCol={{
        span: 6,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      className='login-form'
    >
      <Form.Item
        label='Username'
        name='username'
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input size='middle' />
      </Form.Item>
      <Form.Item
        label='Password'
        name='password'
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 9,
          span: 16,
        }}
      >
        <Row align='middle'>
          <Col span={2}>
            <Button type='primary' htmlType='submit'>
              Login
            </Button>
          </Col>
          <Col offset={1} span={2}>
            <Link to={Routes.register}>Register</Link>
          </Col>
          <Col offset={1} span={3}>
            <Link to={Routes.forgotUsername}>Forgot username</Link>
          </Col>
        </Row>
        <Row align='middle'>
          <Col offset={6}>
            <Link to={Routes.forgotPassword}>Forgot password</Link>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default LoginPage;
