import axios from 'axios';
import { message } from 'antd';

import mem from 'mem';
import routes from './routes';
import messages from './messages';
import { UserTokens } from './types';
import ProfilePage from './components/ProfileView';
import axiosErrorHandler from '../core/utilities/axiosErrorHandler';
import TokenService from './token';

// const appDB = (): AppDBType => {
//  const ret = {
//    token: '',
//    user: {
//      username: '',
//      firstname: '',
//      lastname: '',
//    },
//  };
//  const token = localStorage.getItem('token');
//  if (token) {
//    ret.token = token;
//  }
//
//  return ret;
// };

/**
 * @param username
 * @param password
 * @brief Authenticating using username and password, if it succeeds, save the authenticated info (JWT) to localstorage, otherwise return false.
 *        - Check if the
 *        - Call authentication API
 *          - sucess:
 *            - save JWT into localStorage
 *            - call API to get user info
 *          - fail: show error message
 * @returns
 */
const authenticate = async (
  username: string,
  password: string
): Promise<boolean> => {
  if (localStorage.getItem('user')) {
    message.error(messages.already_signed_in_msg);
    return false;
  }

  try {
    const res = (
      await axios.post(routes.api.obtainPairURL, { username, password })
    ).data as UserTokens;
    TokenService.setUser(res);
    return true;
  } catch (error) {
    message.info('Authentication failed');
    return false;
  }
};

/**
 * @brief Try to refresh access token
 * @returns
 */
const refreshAccessToken = async (): Promise<any> => {
  try {
    const response = await axios.post(routes.api.refreshURL, {
      refresh: TokenService.getLocalRefreshToken(),
    });
    const { access } = response.data;
    TokenService.updateLocalAccessToken(access);
    return true;
  } catch (error) {
    message.info('Please login again');
    return error;
  }
};

/**
 * @brief Verify if the JWT token is valid. If not, try to refresh token. If can refresh => auto login
 * @returns
 */
const verifyToken = async (retried: boolean): Promise<boolean> => {
  const token = TokenService.getLocalAccessToken();

  if (!token) {
    logout();
    return false;
  }
  try {
    await axios.post(routes.api.verifyURL, { token });
    return true;
  } catch (error) {
    if (!retried) return await verifyToken(true);
    logout();
    return false;
  }
};

/**
 * @brief
 * @returns
 */
const logout = async (): Promise<void> => {
  localStorage.removeItem('user');
};

const memoizedRefreshAccessToken = mem(refreshAccessToken, {
  maxAge: 10000,
});

export {
  authenticate,
  logout,
  memoizedRefreshAccessToken,
  verifyToken,
  ProfilePage,
};
