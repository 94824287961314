import { Button, Form, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosErrorHandler, restful } from '../../core';
import { Routes } from '../../routes';
import { ResourceNames } from '../constants';
import { useAuth } from '../hook';

export default ({ token }: { token: string }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const passwordResetAPI = restful(ResourceNames.passwordReset);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        await passwordResetAPI.postListAction({
          action: 'validate_token',
          data: { token },
        });
      } catch (err: any) {
        if (err.response.status === 404) {
          message.error('Invalid reset token');
        } else {
          axiosErrorHandler(err, true);
        }
        navigate(Routes.home);
      }
      setLoading(false);
    };
    load();
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      await passwordResetAPI.postListAction({
        action: 'confirm',
        data: { token, password: form.getFieldValue('password') },
      });
      navigate(Routes.home);
      message.success('Reset password successfully!');
      await auth.logout();
    } catch (err: any) {
      if (err.response.status === 400) {
        message.error(
          'Please make sure your password satisfy all requirements'
        );
      } else {
        axiosErrorHandler(err, true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      labelCol={{
        span: 9,
      }}
      wrapperCol={{
        span: 6,
      }}
      name='register'
      onFinish={onFinish}
      scrollToFirstError
      validateTrigger=''
    >
      <Form.Item
        name='password'
        label='Password'
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name='confirm'
        label='Confirm Password'
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!')
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label='Requirements'>
        <span className='ant-form-text'>
          <li>Not a common password or too similar to your username</li>
          <li>Have at least 8 characters</li>
          <li>Have at least 1 lowercase character</li>
          <li>Have at least 1 uppercase character</li>
          <li>Have at least 1 digit (0 to 9)</li>
        </span>
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 9,
        }}
      >
        <Button loading={loading} type='primary' htmlType='submit'>
          Reset password
        </Button>
      </Form.Item>
    </Form>
  );
};
