import React, { useState, useEffect } from 'react';
import { Layout, Menu, Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import { useAuth, useProfile } from '../account/hook';
import './style.css';

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

function AuthStatus() {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.isAuthenticated) {
    return <Text />;
  }

  return (
    <p>
      Welcome !{' '}
      <button
        onClick={() => {
          auth.logout(() => navigate('/'));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

export default ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const profile = useProfile();
  const defaultSelectedKey = 'Profile';
  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey);
  const year = new Date().getFullYear();

  const menuitems = [
    { label: 'Ideas', key: '/ideas' },
    {
      label: profile.profile?.username,
      key: 'submenu',
      children: [
        { label: 'Profile', key: '/profile' },
        { label: 'Log out', key: 'logout' },
      ],
    },
  ];

  useEffect(() => {
    // console.log(location.pathname);
    for (const menuitem of menuitems) {
      if (location.pathname.indexOf(menuitem.key) === 0) {
        setSelectedKey(menuitem.key);
        return;
      }
    }
  }, [location.pathname]);

  const onClick = ({ item, key, keyPath, domEvent }: any) => {
    if (key !== 'logout') {
      setSelectedKey(key);
      navigate(key);
    } else {
      auth.logout();
    }
  };

  return (
    <Layout>
      <Header>
        <a href='/' className='logo'>
          <span className='logo-color'>
            <HomeFilled className='logo-margin' />
            Alpha capture
          </span>
        </a>
        <div>
          {auth.isAuthenticated && (
            <Menu
              theme='dark'
              mode='horizontal'
              defaultSelectedKeys={[selectedKey]}
              selectedKeys={[selectedKey]}
              items={menuitems}
              onClick={onClick}
            />
          )}
        </div>
      </Header>
      <Content className='content-padding'>
        <div className='site-layout-content'>{children}</div>
      </Content>
      <Footer className='copyright'>
        © {year} Dynamic Technology Lab Pte Ltd. All Rights Reserved.
      </Footer>
    </Layout>
  );
};
