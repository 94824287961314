import { useState, useEffect } from 'react';
import { Button, Form, Input, message, Select, Popconfirm, Space } from 'antd';
import { ResourceNames } from '../constants';
import restful from '../../core/utilities/restful';
import {
  Conviction,
  Currency,
  IdeaType,
  IIdea,
  IStock,
  Style,
  TimeHorizon,
} from '../types';
import { axiosErrorHandler } from '../../core';

const { Option } = Select;

const TickerSelect = ({
  value,
  onSelect,
}: {
  value: null | string;
  onSelect: (value: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [tickers, setTickers] = useState<Array<string>>([]);

  const stockAPI = restful(ResourceNames.stocks);

  const onSearch = async (searchString: string) => {
    try {
      setLoading(true);
      const response = await stockAPI.getDetailAction({
        action: 'find_5_tickers',
        params: { tickerFilter: searchString },
      });
      setTickers(response.data);
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Select
      showSearch
      value={value}
      loading={loading}
      onSearch={onSearch}
      onSelect={onSelect}
    >
      {tickers.map((option: string) => (
        <Option key={option}>{option}</Option>
      ))}
    </Select>
  );
};

const ItemSelect = ({
  placeholder,
  value,
  defaultValue,
  onSelect,
  options,
  doFilterSort,
}: {
  placeholder?: string;
  value: null | string;
  defaultValue?: string;
  onSelect: (value: string) => void;
  options: Array<string>;
  doFilterSort?: boolean;
}) => (
  <Select
    showSearch
    optionFilterProp='children'
    placeholder={placeholder}
    onSelect={onSelect}
    value={value}
    defaultValue={defaultValue}
    filterOption={(input, option) =>
      (option!.children as unknown as string)
        .toLowerCase()
        .includes(input.toLowerCase())
    }
    filterSort={
      doFilterSort
        ? (optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
        : undefined
    }
  >
    {options.map((option: string) => (
      <Option key={option} value={option}>
        {option}
      </Option>
    ))}
  </Select>
);

// https://ant.design/components/input/#API
const NumericInput = (props: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const { value, onChange } = props;

  const formatNumber = (value: number) => new Intl.NumberFormat().format(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '') {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    if (value) {
      let valueTemp = value;
      if (value.charAt(value.length - 1) === '.') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    }
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder='Input a number or leave empty'
      maxLength={30}
    />
  );
};

export default ({
  onSubmitPostProcess,
  closeDrawer,
  latestIdea,
}: {
  onSubmitPostProcess: () => Promise<void>;
  closeDrawer: () => void;
  latestIdea: IIdea | null;
}) => {
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState<Array<IStock>>([]);
  const [form] = Form.useForm();

  const ideaAPI = restful(ResourceNames.ideas);

  const stockAPI = restful(ResourceNames.stocks);

  useEffect(() => {
    if (latestIdea !== null) {
      form.setFieldsValue({
        timeHorizon: latestIdea.timeHorizon,
        conviction: latestIdea.conviction,
        investmentAmount: latestIdea.investmentAmount,
        investmentCurrency: latestIdea.investmentCurrency,
        style: latestIdea.style,
      });
    } else {
      form.setFieldsValue({
        conviction: Conviction.Medium,
        investmentCurrency: Currency.Empty,
        style: Style.Empty,
      });
    }
  }, []);

  // logic when select ticker
  const onSelectTicker = async (ticker: string) => {
    try {
      setLoading(true);
      const response = await stockAPI.getDetailAction({
        action: 'find_stocks_by_ticker',
        params: { ticker },
      });
      setStocks(response.data);
      form.setFieldsValue({
        stockTicker: ticker,
        exchangeCode: '',
        bbid: null,
        exchangeName: null,
        stockPriceCurrency: '',
      });
    } catch (err: any) {
      axiosErrorHandler(err, true);
    } finally {
      setLoading(false);
    }
  };

  // logic when select exchange
  const onSelectExchange = (exchangeName: string) => {
    const stock = stocks.find(stock => stock.exchangeName === exchangeName);
    if (stock) {
      form.setFieldsValue({
        exchangeName,
        exchangeCode: stock.exchangeCode,
        bbid: stock.bbid,
        stockPriceCurrency: stock.currency,
      });
    }
  };

  // logic when select for most fields
  const onChangeField = (field: string, value: string) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const onSubmitIdea = async () => {
    try {
      setLoading(true);
      for (const key of ['targetPrice', 'investmentAmount']) {
        if (form.getFieldValue(key) === '') {
          form.setFieldValue(key, null);
        }
      }
      await ideaAPI.create({ data: form.getFieldsValue() });
      await onSubmitPostProcess();
      closeDrawer();
      message.success('Idea created');
    } catch (err: any) {
      if (err.response?.status === 400) {
        message.error('Please select required fields');
      } else {
        axiosErrorHandler(err, true);
      }
    } finally {
      setLoading(false);
    }
  };

  const getRequireMessage = (s: string) => `Please select ${s}`;

  const onReset = () => {
    form.resetFields();
    if (latestIdea !== null) {
      form.setFieldsValue({
        timeHorizon: latestIdea.timeHorizon,
        conviction: latestIdea.conviction,
        investmentAmount: latestIdea.investmentAmount,
        investmentCurrency: latestIdea.investmentCurrency,
        style: latestIdea.style,
      });
    } else {
      form.setFieldsValue({
        investmentCurrency: Currency.Empty,
        style: Style.Empty,
      });
    }
  };

  return (
    <Form form={form} layout='vertical' name='ideaForm' onFinish={onSubmitIdea}>
      <Form.Item
        label='Stock ticker'
        name='stockTicker'
        rules={[{ required: true, message: getRequireMessage('stock ticker') }]}
      >
        <TickerSelect
          value={form.getFieldValue('stockTicker')}
          onSelect={onSelectTicker}
        />
      </Form.Item>
      <Form.Item
        label='Exchange'
        name='exchangeName'
        rules={[{ required: true, message: getRequireMessage('exchange') }]}
      >
        <ItemSelect
          placeholder='Select ticker first'
          value={form.getFieldValue('exchangeName')}
          onSelect={onSelectExchange}
          options={stocks.map(stock => stock.exchangeName)}
          doFilterSort
        />
      </Form.Item>
      <Form.Item name='exchangeCode' hidden />
      <Form.Item name='bbid' hidden />
      <Form.Item
        label='Stock price currency'
        name='stockPriceCurrency'
        rules={[
          {
            required: true,
            message: getRequireMessage('stock price currency'),
          },
        ]}
      >
        <Input
          readOnly
          placeholder='This field is auto filled by selecting exchange'
          value={form.getFieldValue('stockPriceCurrency')}
        />
      </Form.Item>
      <Form.Item
        label='Idea type'
        name='ideaType'
        rules={[{ required: true, message: getRequireMessage('idea type') }]}
      >
        <ItemSelect
          value={form.getFieldValue('ideaType')}
          onSelect={value => onChangeField('ideaType', value)}
          options={Object.values(IdeaType)}
          doFilterSort
        />
      </Form.Item>
      <Form.Item
        label='Time horizon'
        name='timeHorizon'
        rules={[{ required: true, message: getRequireMessage('time horizon') }]}
      >
        <ItemSelect
          value={form.getFieldValue('timeHorizon')}
          onSelect={value => onChangeField('timeHorizon', value)}
          options={Object.values(TimeHorizon)}
        />
      </Form.Item>
      <Form.Item
        label='Conviction'
        name='conviction'
        rules={[{ required: true, message: getRequireMessage('conviction') }]}
      >
        <ItemSelect
          value={form.getFieldValue('conviction')}
          onSelect={value => onChangeField('conviction', value)}
          options={Object.values(Conviction)}
        />
      </Form.Item>
      <Form.Item label='Target price' name='targetPrice'>
        <NumericInput
          value={form.getFieldValue('targetPrice')}
          onChange={value => onChangeField('targetPrice', value)}
        />
      </Form.Item>
      <Form.Item label='Investment amount' name='investmentAmount'>
        <NumericInput
          value={form.getFieldValue('investmentAmount')}
          onChange={value => onChangeField('investmentAmount', value)}
        />
      </Form.Item>
      <Form.Item label='Investment currency' name='investmentCurrency'>
        <ItemSelect
          value={form.getFieldValue('investmentCurrency')}
          defaultValue={Currency.Empty}
          onSelect={value => onChangeField('investmentCurrency', value)}
          options={Object.values(Currency)}
        />
      </Form.Item>
      <Form.Item
        label='Style'
        name='style'
        rules={[{ message: getRequireMessage('style') }]}
      >
        <ItemSelect
          value={form.getFieldValue('style')}
          onSelect={value => onChangeField('style', value)}
          options={Object.values(Style)}
        />
      </Form.Item>
      <Form.Item label='Comment' name='comment'>
        <Input
          value={form.getFieldValue('comment')}
          onChange={e => onChangeField('comment', e.target.value)}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          <Popconfirm title='Confirm creation?' onConfirm={onSubmitIdea}>
            <Button loading={loading} type='primary' htmlType='submit'>
              Submit
            </Button>
          </Popconfirm>
          <Popconfirm title='Confirm to reset fields?' onConfirm={onReset}>
            <Button htmlType='button'>Reset</Button>
          </Popconfirm>
        </Space>
      </Form.Item>
    </Form>
  );
};
