export const ResourceNames = {
  users: 'users',
  ideas: 'ideas',
  stocks: 'stocks',
  history: 'history',
};

export enum DrawerState {
  ideaCreate,
  ideaEdit,
  closed,
}
